import React, { useContext, useEffect, useState } from "react";
import LayoutComponent from "../components/shared/Layout";
import GameMenu from "../components/GameMenu";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { FaAngleLeft } from "react-icons/fa";
import { isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import NoEvent from "../components/NoEvent";
import { BiUpArrowCircle } from "react-icons/bi";
import obj from "../Utils/helpers";
import Reveal, { Fade } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

const Leagues = () => {
  const navigate = useNavigate();
  const { gameTab, setGameTab } = useContext(AuthContext);
  const [list, setList] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [updatedList, setUpdatedList] = useState([]);
  const [selected, setSelected] = useState({});
  const getData = async () => {
    const { status, data } = await apiGet(
      apiPath.seriesList + "?domain=velkiiex123.live"
    );
    if (data?.success) {
      setList(data?.results);
      setUpdatedList(() => {
        if (gameTab == "all") {
          return filteredArray(data?.results);
        } else {
          return filteredArray(
            data?.results?.filter((res) => {
              return res?.gameType == gameTab;
            })
          );
        }
      });
    }
  };

  const filteredArray = (array) => {
    if (array?.length > 0) {
      let check = array?.filter((res) => {
        return res?.seriesName == "Others";
      });
      if (check?.length > 1) {
        let find = check?.find((res) => {
          return res?.seriesName == "Others";
        });
        return [
          ...array?.filter((res) => {
            return res?.seriesName !== "Others";
          }),
          {
            ...find,
            matchList: check
              ?.map((res) => {
                return res?.matchList;
              })
              .flat(),
          },
        ];
      } else {
        return array;
      }
    }
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      getData();
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    setUpdatedList(() => {
      if (gameTab == "all") {
        return list;
      } else {
        return list?.filter((res) => {
          return res?.gameType == gameTab;
        });
      }
    });
  }, [gameTab]);

  const customAnimation = keyframes`
  from {
    opacity: 0.4;
    background:black
  }

  to {
    opacity: 1; 
    background:black
  }
`;
  return (
    <Reveal keyframes={customAnimation}>
      <Fade cascade duration={900}>
        <div>
          {" "}
          <LayoutComponent visibilityType={true}>
            {isEmpty(selected) && (
              <div>
                <GameMenu loader={true} game={gameTab} setGame={setGameTab} />
                {updatedList?.length > 0 ? (
                  <div className="d-flex p-3 leage-list main-casino-wrapper">
                    <div
                      className={`main-list  w-100 ${
                        updatedList?.length > 7 ? "mb-8" : ""
                      }`}
                    >
                      <h6 className="mb-3">Popular</h6>
                      {updatedList?.map((item, index) => {
                        return (
                          <div
                            className="w-100 match-list "
                            style={{ marginBottom: `.0714285714rem` }}
                          >
                            <ul className="p-0 m-0">
                              <li
                                onClick={() => setSelected(item)}
                                className="Biuparrow"
                              >
                                <i class="icon-top"></i> {item?.seriesName}
                              </li>
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}

            {/* second-level */}
            {!isEmpty(selected) && (
              <div className="second-level-leage">
                <div className="balance-label position-relative text-start ps-5">
                  <button
                    onClick={() => setSelected({})}
                    className="bg-transparent border-0 text-white position-absolute start-0 pt-0"
                  >
                    <FaAngleLeft className="fs-3" />
                  </button>
                  {selected?.seriesName}
                </div>

                <div className="d-flex p-3 px-4 leage-list main-casino-wrapper">
                  <div
                    className={`main-list w-100 ${
                      selected?.matchList?.length > 7 ? "mb-5" : ""
                    }`}
                  >
                    {selected?.matchList?.map((item, index) => {
                      return (
                        <div className="w-100 mb-3 match-list submatchlist">
                          <ul className="p-0 m-0">
                            <div class="flex flex-1 items-center">
                              <div class="inline-flex items-center h-full mr-4px">
                                <div class="">
                                  <div class="time-block flex items-center justify-center leading-9px">
                                    <span class="text-submenu py-px text-center text-8 px-2px pb-0">
                                      {obj?.matchDateTime(
                                        item?.eventDateTime,
                                        "same"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="text-10 text-black-600 font-normal">
                                <span class="pr-1">Matched</span>
                                <span>{item?.totalMatched}</span>
                              </div>
                            </div>
                            <li
                              onClick={() =>
                                navigate(
                                  `/full-market/${item?.marketId}/${item?.eventId}`
                                )
                              }
                            >
                              {item?.runners?.map((res, index) => {
                                return (
                                  <span className="">
                                    {index > 0 ? " - " : ""}
                                    {res?.RunnerName}
                                  </span>
                                );
                              })}
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            {/* second-level */}
          </LayoutComponent>
        </div>
      </Fade>
    </Reveal>
  );
};

export default Leagues;
