import React, { useContext, useEffect, useState } from "react";
import LayoutComponent from "../components/shared/Layout";
import GameMenu from "../components/GameMenu";
import SportAccordian from "../components/SportAccordian";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
import jwt_decode from "jwt-decode";
import { FiSearch } from "react-icons/fi";
import { BiSortAlt2 } from "react-icons/bi";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
import NoEvent from "../components/NoEvent";
import { io } from "socket.io-client";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";
import SearchBar from "../components/SearchBar";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty, startCase } from "lodash";
import { createPortal } from "react-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { TbStarOff } from "react-icons/tb";
const Sports = () => {
  const {
    setGameTab,
    gameTab,
    keyTime,
    setKeyTime,
    user,
    profileData,
    setSearchTab,
    searchTab,
    parly,
    setParly,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const { setLimit } = useContext(BetSlipContext);
  const [list, setList] = useState([]);
  const [show, setShow] = useState(false);
  const [odds, setOdds] = useState([]);
  const [eventId, setEventId] = useState([]);
  const [eventIdPremium, setEventIdPremium] = useState([]);
  const [activeKeys, setActiveKeys] = useState([0]);
  const [activeFav, setActiveFav] = useState([]);
  const [newResult, setNewResult] = useState([]);
  const [updated, setUpdated] = useState({
    cricket: [],
    tennis: [],
    soccer: [],
  });
  const [activeKeysAll, setActiveKeysAll] = useState({
    cricket: [],
    tennis: [],
    soccer: [],
  });
  const [allShow, setAllShow] = useState({
    cricket: false,
    tennis: false,
    soccer: false,
  });
  const getData = async () => {
    setLoader(true);
    const { status, data } = await apiGet(apiPath.seriesList, {
      gameType: gameTab == "all" ? "" : gameTab,
      type: keyTime,
      userId: !isEmpty(localStorage.getItem("token"))
        ? jwt_decode(localStorage.getItem("token"))?.user?._id
        : "",
    });
    if (data?.success) {
      let newResult = data?.results
        ?.map((res) => {
          return res?.matchList;
        })
        .flat();
      setEventId(
        newResult
          ?.filter((res) => {
            return res?.hasMatchOdds;
          })
          ?.map((res) => {
            return res?.eventId;
          })
      );
      setEventIdPremium(
        newResult?.map((res) => {
          return res?.eventId;
        })
      );
      if (gameTab == "all") {
        let obj = {
          cricket:
            filteredArray(
              data?.results?.filter((res) => res?.gameType == "cricket")
            ) || [],
          tennis:
            filteredArray(
              data?.results?.filter((res) => res?.gameType == "tennis")
            ) || [],
          soccer:
            filteredArray(
              data?.results?.filter((res) => res?.gameType == "soccer")
            ) || [],
        };
        setUpdated(obj);
        setActiveKeysAll({
          cricket:
            obj?.cricket?.length > 0
              ? obj?.cricket
                  ?.filter((res) => {
                    return res?.hasMatchOddsInList;
                  })
                  ?.map((res) => {
                    return res?.seriesId;
                  })
              : [],
          soccer:
            obj?.soccer?.length > 0
              ? obj?.soccer
                  ?.filter((res) => {
                    return res?.hasMatchOddsInList;
                  })
                  ?.map((res) => {
                    return res?.seriesId;
                  })
              : [],
          tennis:
            obj?.tennis?.length > 0
              ? obj?.tennis
                  ?.filter((res) => {
                    return res?.hasMatchOddsInList;
                  })
                  ?.map((res) => {
                    return res?.seriesId;
                  })
              : [],
        });
      } else {
        let temp = data?.results
          ?.filter((res) => {
            return res?.hasMatchOddsInList;
          })
          ?.map((res) => {
            return res?.seriesId;
          });
        setActiveKeys(temp);
      }
      let newArray = filteredArray(data?.results);
      setList(newArray);
      setActiveFav(
        data?.results
          ?.map((res) => {
            return res?.matchList;
          })
          .flat()
          ?.filter((res) => {
            return res?.isFavourite;
          })
          ?.map((res) => {
            return res?.eventId;
          })
      );
      setNewResult(newResult);

      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const filteredArray = (array) => {
    if (array?.length > 0) {
      let check = array?.filter((res) => {
        return res?.seriesName == "Others";
      });
      if (check?.length > 1) {
        let find = check?.find((res) => {
          return res?.seriesName == "Others";
        });
        return [
          ...array?.filter((res) => {
            return res?.seriesName !== "Others";
          }),
          {
            ...find,
            matchList: check
              ?.map((res) => {
                return res?.matchList;
              })
              .flat(),
          },
        ];
      } else {
        return array;
      }
    }
  };
  useEffect(() => {
    getData();
  }, [gameTab, keyTime]);

  const handleSelect = (event, type) => {
    if (gameTab !== "all") {
      if (activeKeys.includes(event)) {
        setActiveKeys(activeKeys.filter((res, index) => res !== event));
      } else {
        setActiveKeys([...activeKeys, event]);
      }
    } else {
      updateActive(event, type);
    }
  };
  const obj = {
    cricket: activeKeysAll?.cricket || [],
    tennis: activeKeysAll?.tennis || [],
    soccer: activeKeysAll?.soccer || [],
  };
  const updateActive = (event, type) => {
    if (obj[type].includes(event)) {
      if (type == "cricket") {
        setActiveKeysAll({
          ...activeKeysAll,
          cricket: obj[type].filter((res, index) => res !== event),
        });
      } else if (type == "tennis") {
        setActiveKeysAll({
          ...activeKeysAll,
          tennis: obj[type].filter((res, index) => res !== event),
        });
      } else if (type == "soccer") {
        setActiveKeysAll({
          ...activeKeysAll,
          soccer: obj[type].filter((res, index) => res !== event),
        });
      }
    } else {
      if (type == "cricket") {
        setActiveKeysAll({
          ...activeKeysAll,
          cricket: [...activeKeysAll?.cricket, event],
        });
      } else if (type == "tennis") {
        setActiveKeysAll({
          ...activeKeysAll,
          tennis: [...activeKeysAll?.tennis, event],
        });
      } else if (type == "soccer") {
        setActiveKeysAll({
          ...activeKeysAll,
          soccer: [...activeKeysAll?.soccer, event],
        });
      }
    }
  };

  const handelChanges = (type) => {
    if (type == "cricket") {
      let temp = allShow?.cricket ? false : true;
      setAllShow({ ...allShow, cricket: temp });
      if (temp) {
        setActiveKeysAll({
          ...activeKeysAll,
          cricket: Array(updated?.cricket?.length)
            .fill(0)
            .map((res, index) => {
              return res?.seriesId;
            }),
        });
      } else {
        setActiveKeysAll({
          ...activeKeysAll,
          cricket: [],
        });
      }
    } else if (type == "tennis") {
      let temp = allShow?.tennis ? false : true;
      setAllShow({ ...allShow, tennis: temp });
      if (temp) {
        setActiveKeysAll({
          ...activeKeysAll,
          tennis: Array(updated?.tennis?.length)
            .fill(0)
            .map((res, index) => {
              return res?.seriesId;
            }),
        });
      } else {
        setActiveKeysAll({
          ...activeKeysAll,
          tennis: [],
        });
      }
    } else if (type == "soccer") {
      let temp = allShow?.soccer ? false : true;
      setAllShow({ ...allShow, soccer: temp });
      if (temp) {
        setActiveKeysAll({
          ...activeKeysAll,
          soccer: Array(updated?.soccer?.length)
            .fill(0)
            .map((res, index) => {
              return res?.seriesId;
            }),
        });
      } else {
        setActiveKeysAll({
          ...activeKeysAll,
          soccer: [],
        });
      }
    } else {
      let temp = show ? false : true;
      setShow(temp);
      if (temp) {
        if (gameTab !== "all") {
          setActiveKeys(
            list?.map((res, index) => {
              return res?.seriesId;
            })
          );
        }
      } else {
        console.log("111");
        setActiveKeys([]);
      }
    }
  };

  // Socket
  const [checkRefresh, setCheckRefresh] = useState(true);
  const [socketObj, setSocketObj] = useState(null);
  const [score, setScore] = useState([]);
  const getScore = (message) => {
    setScore(message);
  };
  const getSocket = () => {
    if (checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${process.env.REACT_APP_API_SCORE_URL}?token=${randomId}&userType=front`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(
          `${process.env.REACT_APP_API_SCORE_URL}?user_id=${randomId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      }
      setSocketObj(newSocket);
      newSocket.on("scoreAll", getScore);
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => newSocket.close();
    }
  };

  useEffect(() => {
    if (checkRefresh) {
      getSocket();
    }
  }, [checkRefresh]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      setCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setCheckRefresh(false);
    }
  });

  const getMatchOddsMulti = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds +
        `?marketId=${id
          ?.map((res) => {
            return res?.marketId;
          })
          ?.join(",")}&multi=true&eventIds=${id
          ?.map((res) => {
            return res?.eventId;
          })
          ?.join(",")}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        setOdds(socket_data);
      }
    }
  };

  useEffect(() => {
    if (newResult?.length > 0 && !parly) {
      getMatchOddsMulti(newResult);
    }
  }, [newResult, parly]);

  useEffect(() => {
    if (newResult?.length > 0) {
      let interval = setInterval(() => {
        if (!parly) {
          getMatchOddsMulti(newResult);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [newResult, parly]);

  return (
    <div>
      <LayoutComponent visibilityType={true}>
        {searchTab ? (
          <SearchBar />
        ) : (
          <>
            <div className="parlay-tab">
              <Form className="d-flex align-items-center">
                <div
                  style={{ alignItems: "center" }}
                  className="parlay-checkbox d-flex"
                >
                  <Form.Check
                    onClick={() => {
                      if (isEmpty(user)) {
                        navigate("/login");
                      } else {
                        setParly(!parly);
                      }
                    }}
                    checked={parly}
                    className="parly-check"
                    type="switch"
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      color: parly ? "#ffc800" : "",
                    }}
                  >
                    Parlay
                  </span>
                </div>
                <div className="parlay-inner-tab w-100 justify-content-between d-flex align-items-center">
                  <div>
                    <ul className="mb-0 p-0">
                      <li
                        onClick={() => setKeyTime("in_play")}
                        className={keyTime === "in_play" ? "active" : ""}
                      >
                        In-Play
                      </li>
                      <li
                        onClick={() => setKeyTime("today")}
                        className={keyTime === "today" ? "active" : ""}
                      >
                        Today
                      </li>
                      <li
                        onClick={() => setKeyTime("tomorrow")}
                        className={keyTime === "tomorrow" ? "active" : ""}
                      >
                        Tomorrow
                      </li>
                    </ul>
                  </div>

                  <div className="parlay-icons">
                    <i
                      class="icon-star-solid text-icon-light "
                    ></i>

                    <i
                      class="icon-search text-icon-light "
                      onClick={() => setSearchTab(true)}
                    ></i>
                  </div>
                </div>
              </Form>
            </div>
            <GameMenu game={gameTab} type="home" setGame={setGameTab} />
            <div className="all-complete">
              <DropdownButton
                id="abc"
                title={
                  <div>
                    <i class="icon-toggle rotate-90"></i>
                    by Competition
                    <i class="icon-triangle divtriangle"></i>
                  </div>
                }
                className=""
              >
                <Dropdown.Item>by Time</Dropdown.Item>
              </DropdownButton>
              {gameTab !== "all" && (
                <div onClick={handelChanges} class="all-complete-all">
                  <span class="mr-1">ALL</span>
                  {!show ? (
                    <MdKeyboardDoubleArrowDown />
                  ) : (
                    <MdKeyboardDoubleArrowUp />
                  )}
                </div>
              )}
            </div>
            {loader ? (
              <div>
                <SkeletonTheme baseColor="#d4e0e5">
                  <div className="p-2">
                    <div className="skeletion-new p-3">
                      <div className="d-flex mb-2">
                        <Skeleton
                          circle
                          height={20}
                          width={20}
                          style={{ marginRight: "10px" }}
                        />
                        <Skeleton height={17} width={120} />{" "}
                      </div>
                      <Skeleton height={17} width={"100%"} />{" "}
                      <Skeleton height={17} width={"70%"} />
                    </div>
                  </div>
                </SkeletonTheme>
                <SkeletonTheme baseColor="#d4e0e5">
                  <div className="p-2">
                    <div className="skeletion-new p-3">
                      <div className="d-flex mb-2">
                        <Skeleton
                          circle
                          height={20}
                          width={20}
                          style={{ marginRight: "10px" }}
                        />
                        <Skeleton height={17} width={120} />{" "}
                      </div>
                      <Skeleton height={17} width={"100%"} />{" "}
                      <Skeleton height={17} width={"70%"} />
                    </div>
                  </div>
                </SkeletonTheme>{" "}
                <SkeletonTheme baseColor="#d4e0e5">
                  <div className="p-2">
                    <div className="skeletion-new p-3">
                      <div className="d-flex mb-2">
                        <Skeleton
                          circle
                          height={20}
                          width={20}
                          style={{ marginRight: "10px" }}
                        />
                        <Skeleton height={17} width={120} />{" "}
                      </div>
                      <Skeleton height={17} width={"100%"} />{" "}
                      <Skeleton height={17} width={"70%"} />
                    </div>
                  </div>
                </SkeletonTheme>{" "}
              </div>
            ) : gameTab == "all" ? (
              <>
                {updated?.cricket?.length > 0 && (
                  <div className="bg-skylight p-3 px-sm-2 pt-1 px-1">
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h6 className="sports-heading">Cricket</h6>
                        <div
                          onClick={() => handelChanges("cricket")}
                          class="all-complete-all"
                        >
                          <span class="mr-1">ALL</span>
                          {!allShow?.cricket ? (
                            <MdKeyboardDoubleArrowDown />
                          ) : (
                            <MdKeyboardDoubleArrowUp />
                          )}
                        </div>
                      </div>
                      {updated?.cricket?.length > 0
                        ? updated?.cricket?.map((item, index) => {
                            return (
                              <SportAccordian
                                profileData={profileData}
                                activeKeys={activeKeysAll?.cricket}
                                handleSelect={handleSelect}
                                user={user}
                                showOdds={parly ? false : true}
                                type="cricket"
                                activeFav={activeFav}
                                odds={odds}
                                setActiveFav={setActiveFav}
                                keyTime={keyTime}
                                mainList={updated}
                                item={item}
                                index={index}
                                eventId={eventId}
                                setLimit={setLimit}
                                score={score}
                              />
                            );
                          })
                        : ""}
                    </div>
                  </div>
                )}
                {updated?.tennis?.length > 0 && (
                  <div className="bg-skylight p-3 px-sm-2 pt-1 px-1">
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h6 className="sports-heading">Tennis</h6>
                        <div
                          onClick={() => handelChanges("tennis")}
                          class="all-complete-all"
                        >
                          <span class="mr-1">ALL</span>
                          {!allShow?.tennis ? (
                            <MdKeyboardDoubleArrowDown />
                          ) : (
                            <MdKeyboardDoubleArrowUp />
                          )}
                        </div>
                      </div>
                      {updated?.tennis?.length > 0
                        ? updated?.tennis?.map((item, index) => {
                            return (
                              <SportAccordian
                                profileData={profileData}
                                user={user}
                                keyTime={keyTime}
                                activeFav={activeFav}
                                setActiveFav={setActiveFav}
                                activeKeys={activeKeysAll?.tennis}
                                odds={odds}
                                mainList={updated}
                                type="tennis"
                                handleSelect={handleSelect}
                                item={item}
                                eventId={eventId}
                                index={index}
                                setLimit={setLimit}
                                score={score}
                              />
                            );
                          })
                        : ""}
                    </div>
                  </div>
                )}
                {updated?.soccer?.length > 0 && (
                  <div className="bg-skylight p-3 px-sm-2 pt-1 px-1">
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h6 className="sports-heading">Soccer</h6>
                        <div
                          onClick={() => handelChanges("soccer")}
                          class="all-complete-all"
                        >
                          <span class="mr-1">ALL</span>
                          {!allShow?.soccer ? (
                            <MdKeyboardDoubleArrowDown />
                          ) : (
                            <MdKeyboardDoubleArrowUp />
                          )}
                        </div>
                      </div>
                      {updated?.soccer?.length > 0
                        ? updated?.soccer?.map((item, index) => {
                            return (
                              <SportAccordian
                                profileData={profileData}
                                user={user}
                                keyTime={keyTime}
                                activeFav={activeFav}
                                setActiveFav={setActiveFav}
                                activeKeys={activeKeysAll?.soccer}
                                odds={odds}
                                mainList={updated}
                                type="soccer"
                                handleSelect={handleSelect}
                                item={item}
                                eventId={eventId}
                                index={index}
                                setLimit={setLimit}
                                score={score}
                              />
                            );
                          })
                        : ""}
                    </div>
                  </div>
                )}
              </>
            ) : list?.length > 0 ? (
              <div className="bg-skylight p-3 px-sm-2 pt-1 px-1">
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    {/* <h6 className="sports-heading">{startCase(gameTab)}</h6> */}
                    {/* <div
                      onClick={() => handelChanges()}
                      class="all-complete-all"
                    >
                      <span class="mr-1">ALL</span>
                      {!show ? (
                        <MdKeyboardDoubleArrowDown />
                      ) : (
                        <MdKeyboardDoubleArrowUp />
                      )}
                    </div> */}
                  </div>
                  {list?.length > 0 ? (
                    list?.map((item, index) => {
                      return (
                        <SportAccordian
                          profileData={profileData}
                          user={user}
                          keyTime={keyTime}
                          activeKeys={activeKeys}
                          handleSelect={handleSelect}
                          activeFav={activeFav}
                          setActiveFav={setActiveFav}
                          odds={odds}
                          mainList={list}
                          type="all"
                          showOdds={
                            parly ? false : gameTab == "all" ? false : true
                          }
                          item={item}
                          eventId={eventId}
                          index={index}
                          setLimit={setLimit}
                          score={score}
                        />
                      );
                    })
                  ) : (
                    <NoEvent />
                  )}
                </div>
              </div>
            ) : (
              <NoEvent />
            )}
          </>
        )}
      </LayoutComponent>
    </div>
  );
};

export default Sports;
