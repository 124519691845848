import React, { useContext } from "react";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import SidebarLayout from "../components/shared/SidebarLayout";

const Profile = () => {
  const navigate = useNavigate();
  let { user } = useContext(AuthContext);

  return (
    <SidebarLayout heading={"My Profile"}>
      {" "}
      <div className="p-3 inner-sidebar-content">
        <Table className="align-middle bg-white my-profile">
          <tbody>
            <tr>
              <td width="30%">User Name</td>
              <td width="70%" colSpan="2">
                @ {user?.user?.username}
              </td>
            </tr>
            <tr>
              <td width="30%">First Name</td>
              <td width="40%">{user?.user?.firstName}</td>
              <td width="30%" className="text-right">
                <Button
                  onClick={() => navigate("/update-profile")}
                  className="theme-btn py-0 px-2"
                  style={{ fontSize: "0.8571428571428571rem" }}
                >
                  <i class="icon-edit"></i>
                  <span style={{ fontWeight: "400", marginLeft: "5px" }}>
                    Edit
                  </span>
                </Button>
              </td>
            </tr>
            <tr>
              <td width="30%">Last Name</td>
              <td width="40%">{user?.user?.firstName}</td>
              <td width="30%" className="text-right">
                <Button
                  onClick={() => navigate("/update-profile")}
                  className="theme-btn py-0 px-2"
                  style={{ fontSize: "0.8571428571428571rem" }}
                >
                  <i class="icon-edit"></i>
                  <span style={{ fontWeight: "400", marginLeft: "5px" }}>
                    Edit
                  </span>
                </Button>
              </td>
            </tr>
            <tr>
              <td width="30%">Phone</td>
              <td width="70%" colSpan="2">
                {user?.user?.phoneNumber}
              </td>
            </tr>
            <tr>
              <td width="30%">Email</td>
              <td width="40%">{user?.user?.email}</td>
              <td width="30%" className="text-right">
                <Button
                  onClick={() => navigate("/update-profile")}
                  className="theme-btn py-0 px-2"
                  style={{ fontSize: "0.8571428571428571rem" }}
                >
                  <i class="icon-edit"></i>
                  <span style={{ fontWeight: "400", marginLeft: "5px" }}>
                    Edit
                  </span>
                </Button>
              </td>
            </tr>
            {/* <tr>
          <td width="40%">Contact Number</td>
          <td width="60%" colSpan="2">{user?.user?.firstName}</td>
        </tr> */}
            <tr>
              <td width="30%">Password</td>
              <td width="40%">********</td>
              <td width="30%" className="text-right">
                <Button
                  onClick={() => navigate("/change-password")}
                  className="theme-btn py-0 px-2"
                  style={{ fontSize: "0.8571428571428571rem" }}
                >
                  <i class="icon-edit"></i>
                  <span style={{ fontWeight: "400", marginLeft: "5px" }}>
                    Edit
                  </span>
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </SidebarLayout>
  );
};

export default Profile;
