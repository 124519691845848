import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { TiArrowSortedUp } from "react-icons/ti";

const SportAccordianInnerSlip = ({
  res,
  obj,
  premiumDetail,
  handelPaly,
  oldObject,
}) => {
  const [green, setGreen] = useState(false);
  const [red, setRed] = useState(false);
  const redRef = useRef(false);
  const greenRef = useRef(false);
  useEffect(() => {
    if (res?.odds > oldObject?.odds) {
      // console.log('working green')
      setGreen(true);
      setRed(false);
    } else if (res?.odds < oldObject?.odds) {
      // console.log('working red')
      setGreen(false);
      setRed(true);
    } else if (res?.odds == oldObject?.odds) {
      if (red && !redRef.current) {
        redRef.current = true; // Mark as executed
        let timeout = setTimeout(() => {
          setRed(false);
          redRef.current = false;
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
      if (green && !greenRef.current) {
        greenRef.current = true;
        let timeout = setTimeout(() => {
          setGreen(false);
          greenRef.current = false;
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [res, green, red, redRef, greenRef]);
  return (
    <div
      title={`${res?.odds}`}
      id={"parly_" + res?.selectionName}
      onClick={() => handelPaly(premiumDetail, res)}
      className={
        !isEmpty(obj) &&
        obj?.sportsBookSelectionObject?.selectionName == res?.selectionName &&
        obj?.sportsBookSelectionObject?.odds == res?.odds
          ? `active ${
              Number(
                document
                  .getElementById("parly_" + res?.selectionName)
                  ?.title?.trim()
              ) !== res?.odds
                ? "spark-parly"
                : ""
            }`
          : `${
              Number(
                document.getElementById("sportBook" + res?.id)?.title?.trim()
              ) !== res?.odds
                ? "spark-parly"
                : ""
            }`
      }
    >
      {green && (
        <TiArrowSortedUp
          style={{
            position: "absolute",
            top: "-5%",
            right: "-1%",
            transform: "rotate(45deg)",
          }}
          size={19}
          color="green"
        />
      )}
      {red && (
        <TiArrowSortedUp
          style={{
            position: "absolute",
            bottom: "-5%",
            right: "-1%",
            transform: "rotate(135deg)",
          }}
          size={19}
          color="red"
        />
      )}
      <span>{res?.selectionName}</span>
      <strong
        style={
          green
            ? {
                color: "green",
              }
            : red
            ? { color: "red" }
            : {}
        }
      >
        {res?.odds}
      </strong>
    </div>
  );
};

export default SportAccordianInnerSlip;
